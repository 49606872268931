<template>
    <v-content>
      <v-row class="pa-5 align-center">
        <v-col cols="12">
          <h2 class="font-weight-bold text-center">Asignacion de Docentes</h2>
        </v-col>
      </v-row>
      <v-card>
        <v-progress-linear color="primary" indeterminate :height="9" v-if="loading"></v-progress-linear>
        <v-card-title>
          <v-row>
            <v-col cols="4">
              <v-btn icon="mdi-plus" color="primary" title="Nueva Asignacion" to="/nuevaasignacion"
                :disabled="cargando"></v-btn>
            </v-col>
            <v-col cols="8">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar..." single-line
                hide-details></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table dense items-per-page="10" :loading="loading" :search="search" :headers="headers" :items="data"
          item-value="item" items-per-page-text="Total de Registros" class="elevation-1">
          <template v-slot:item.imagen="props">
            <v-img  :src="props.item.Firma" width="50" height="50" @click="mostrarImagen(props.item.Firma)" ></v-img>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon title="Editar" color="success" small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
        <v-dialog v-model="dialogImagen" max-width="800">
          <v-img :src="imagenAmpliada"></v-img>
        </v-dialog>
      </v-card>
    </v-content>
  </template>
  
  <script>
  import Swal from 'sweetalert2'
  export default {
    name: "Asignaciones",
    data() {
      return {
        token : this.$store.getters.token,
        dialogImagen: false,
        imagenAmpliada: '',
        headers: [
          {
            title: "Director",
            align: "start",
            key: "Nombres",
            value: function(item){
                return item.Nombres + " " + item.ApPaterno + " " + item.ApMaterno;
            }
          },
          {
            title: "Escuela",
            align: "start",
            key: "Escuela"
          },
          {
            title: "Fecha Inicio",
            align: "start",
            key: "FechaInicio"
          },
          {
            title: "Fecha Fin",
            align: "start",
            key: "FechaFin"
          },
          {
            title: "Vigencia",
            align: "start",
            key: "Vigencia",
            value: function(item){
              return item.Vigencia == 1 ? "VIGENTE" : "CADUCADO";
            }
          },
          {
            title: "Firma",
            align: "start",
            key: "imagen"
          },
          {
            title: "Opciones",
            align: "start",
            key: "actions",
            sortable: false
          }
        ],
        search: null,
        loading: false,
        data: [],
        snackbarText: "",
        snackbar: false,
        snackbarColor: null
      }
    },
    methods: {
      editItem(item) {
        this.$router.push("/editarasignacion/"+item.CodigoAsignacion)
      },
      mostrarImagen(urlImagen) {
        this.imagenAmpliada = urlImagen;
        this.dialogImagen = true;
      },
      getData(){
        this.loading = true;
        this.axios({
            headers : {
              Authorization : "Bearer " + this.token
            },
            method: "get",
            url : "/asignar"
        }).then(response =>{
            this.data = response.data;
            this.loading = false;
        }).catch(e => {
            let errorMessage = null;
            if (e.hasOwnProperty("response")) {
                errorMessage = e.response.data.message;  
            }else{
                errorMessage = e.message;
            }
            Swal.fire({
                title : "Sistema",
                text : errorMessage,
                icon: "error"
            }).then(()=>{
                this.loading = false;
            });
        });
      },
    },
    created() {
      //this.token = localStorage.getItem("token");
      this.getData();
    }
  
  }
  </script>
  