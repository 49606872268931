<template>
  <v-app>
      <SideMenu :ideRol="idRol" :nameRol="rolName" :username="username" v-if="$route.name != 'Login'"  />
      <v-main>      
        <router-view />
        <v-footer class="d-flex flex-column" v-if="$route.name != 'Login'" >
            <v-col class="text-center">
                <strong>
                    Copyright &copy;{{ new Date().getFullYear() }}
                    Todos los Derechos Reservados.
                </strong>
            </v-col>
        </v-footer>
      </v-main>
  </v-app>
</template>

<script>
import SideMenu from '@/components/SideMenu.vue';
export default {
  name: 'App',
  components: {
    SideMenu
  },
  data: () => ({
    
  }),
  computed : {
      token(){
        return this.$store.getters.token;
      },
      username() {      
          return this.$store.getters.user == null ? null : this.$store.getters.user.usuario;
      },
      rolName(){
          return this.$store.getters.user == null ? null : this.$store.getters.user.rol;
      },
      idRol(){
          return this.$store.getters.user == null ? null : Number(this.$store.getters.user.idrol);
      }
  }
}
</script>
