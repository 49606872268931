<template>
    <v-card>
        <v-progress-linear color="primary" indeterminate :height="9" v-if="cargando"></v-progress-linear>
        <v-card-text>
            <v-row class="pa-5 align-center">
                <v-col cols="11">
                    <v-row>
                        <h2 class="font-weight-regular text-center">Editar Alumno</h2>
                    </v-row>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="4">
                        <v-text-field v-model="codigo" maxLength="8" :rules="fieldRules" label="Cod. Universitario" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field type="email" v-model="correo" :rules="emailRules" label="Correo Institucional" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="nombres" :rules="fieldRules" label="Nombres" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="appaterno" :rules="fieldRules" label="Ap. Paterno" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="apmaterno" :rules="fieldRules" label="Ap. Materno" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="direccion" label="Direccion" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="phone" maxLength="9" v-model="celular" :rules="fieldRules" label="Celular" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="date" v-model="fechaing" :rules="fieldRules" label="Fecha Ingreso" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select label="Escuela" :rules="fieldRules" persistent-hint return-object variant="outlined"
                            v-model="escuela" :items="escuelaData"></v-select>
                    </v-col>
                </v-row>

                <v-btn class="me-4" color="success" :loading="loading" :disabled="loading" depressed @mousedown="validate"
                    @click="guardar">
                    Guardar
                </v-btn>
                <v-btn class="me-4 text-white" color="error" to="/alumno">
                    Regresar
                </v-btn>

            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    data () {
        return {
            id: this.$route.params.id,
            token : this.$store.getters.token,
            loading: false,
            cargando: true,
            nombres : null,
            appaterno: null,
            apmaterno: null,
            fechaing: null,
            celular: null,
            direccion: null,
            codigo: null,
            correo: null,
            escuela : null,
            escuelaData : [],
            valid: true,
            fieldRules: [(v) => !!v || "Campo requerido"],
            emailRules: [
                (v) => !!v || "Campo requerido",
                (v) => /.+@.+\..+/.test(v) || "El correo electronico debe ser valido"
            ]
            
        }
    },
    methods: {
        validate() {
            this.$refs.form.validate();
        },

        crearEntidad(){
            return {
                "direccion": this.direccion,
                "correo": this.correo,
                "codigo": this.codigo,
                "nombres": this.nombres,
                "appaterno": this.appaterno,
                "apmaterno": this.apmaterno,
                "celular": this.celular,
                "fechaing": this.fechaing,
                "escuela": this.escuela.value,
            }
        },
        guardar(){
            if (this.valid == false) return;
            this.loading = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : 'PUT',
                url : '/alumno/'+this.id,
                data: this.crearEntidad()
            }).then(response => {
                this.loading = false;
                Swal.fire({
                    title : "Sistema",
                    text: response.data.message,
                    icon: "success"
                }).then(() => {
                    this.$router.push('/alumno');
                });

            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;  
                }else{
                    errorMessage = e.message;
                }
                Swal.fire({
                    title : "Sistema",
                    text : errorMessage,
                    icon: "error"
                }).then(()=>{
                    this.loading = false;
                });
            })
        },
        getEscuelas(){
            this.loading = true;
            this.axios({
                headers : {
                    Authorization : "Bearer " + this.token
                },
                method: "get",
                url : "/escuela/get/actives"
            }).then(response =>{
                response.data.forEach(x => {                
                    this.escuelaData.push({
                        "title": x.Nombre,
                        "subtitle": x.Siglas,
                        "value": x.CodigoEscuela
                    });
                });
                this.loading = false;
            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;  
                }else{
                    errorMessage = e.message;
                }
                Swal.fire({
                    title : "Sistema",
                    text : errorMessage,
                    icon: "error"
                }).then(()=>{
                    this.loading = false;
                });
            });
        
        },
        getData(){
            this.cargando = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : "GET",
                url : "/alumno/"+this.id
            }).then(response => {
                this.escuela = this.escuelaData.find(x => x.value == response.data.CodigoEscuela);
                this.direccion=  response.data.Direccion;
                this.correo=  response.data.CorreoUniversitario;
                this.codigo=  response.data.CodigoUniversitario;
                this.nombres=  response.data.Nombres;
                this.appaterno=  response.data.ApPaterno;
                this.apmaterno=  response.data.ApMaterno;
                this.celular=  response.data.Celular;
                this.fechaing=  response.data.FechaIngreso;
                this.cargando = false;
            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;  
                }else{
                    errorMessage = e.message;
                }
                Swal.fire({
                    title : "Sistema",
                    text : errorMessage,
                    icon: "error"
                }).then(()=>{
                    this.cargando = true;
                });
            })
        }
    },
    mounted() {
        this.getData();         
    },
    created(){
        this.getEscuelas();
    }
}
</script>

<style></style>