<template>
    <v-card>
        <v-card-text>
            <v-row class="pa-5 align-center">
                <v-col cols="11">
                    <v-row>
                        <h2 class="font-weight-regular text-center">Cambiar Clave</h2>
                    </v-row>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="12">
                        <v-text-field type="password" v-model="claveactual" :rules="fieldRules" label="Clave Actual" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field type="password" v-model="clavenueva" :rules="fieldRules" label="Clave Nueva" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field type="password" v-model="claveaux" :rules="passRules" label="Repetir Clave Nueva" variant="outlined">
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-btn class="me-4" color="success" :loading="loading" :disabled="loading" depressed @mousedown="validate"
                    @click="guardar">
                    Guardar
                </v-btn>
                <v-btn class="me-4 text-white" color="error" @click="refrescar()">
                    Cancelar
                </v-btn>

            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    data () {
        return {
            token : this.$store.getters.token,
            loading: false,
            clavenueva: null,
            claveactual: null,
            claveaux: null,
            valid: true,
            fieldRules: [(v) => !!v || "Campo requerido"], 
            passRules: [
                v => !!v || "Confirmar clave",
                v => v === this.clavenueva || "Claves no coinciden"
            ]
                    
        }
    },
    methods: {
        validate() {
            this.$refs.form.validate();
        },

        refrescar(){
            this.$router.go(0);
        },

        crearEntidad(){
            return {
                "clavenueva": this.clavenueva,
                "claveactual": this.claveactual,
            }
        },
        guardar(){
            if (this.valid == false) return;
            this.loading = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : 'POST',
                url : '/usuario/cambiarclave',
                data: this.crearEntidad()
            }).then(response => {
                this.loading = false;
                Swal.fire({
                    title : "Sistema",
                    text: response.data.message,
                    icon: "success"
                }).then(() => {
                    this.$router.go(0);
                });

            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;  
                }else{
                    errorMessage = e.message;
                }
                Swal.fire({
                    title : "Sistema",
                    text : errorMessage,
                    icon: "error"
                }).then(()=>{
                    this.loading = false;
                });
            })
        },
    },
}
</script>

<style></style>