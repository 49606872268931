<template>
    <v-card>
        <v-card-text>
            <v-row class="pa-5 align-center">
                <v-col cols="11">
                    <v-row>
                        <h2 class="font-weight-regular text-center">Nueva Escuela</h2>
                    </v-row>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="6">
                        <v-select label="Facultad" :rules="fieldRules" persistent-hint return-object variant="outlined"
                            v-model="facultad" :items="facultadData"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="nombre" :rules="fieldRules" label="Nombre" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="correo" type="email" :rules="fieldRules" label="Correo" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="siglas" :rules="fieldRules" label="Siglas" variant="outlined">
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-btn class="me-4" color="success" :loading="loading" :disabled="loading" depressed @mousedown="validate"
                    @click="guardar">
                    Guardar
                </v-btn>
                <v-btn class="me-4 text-white" color="error" to="/escuela">
                    Regresar
                </v-btn>

            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    data () {
        return {
            token : this.$store.getters.token,
            loading: false,
            facultad : null,
            correo : null,
            facultadData : [],
            nombre: null,
            siglas: null,
            valid: true,
            fieldRules: [(v) => !!v || "Campo requerido"],            
        }
    },
    methods: {
        validate() {
            this.$refs.form.validate();
        },

        crearEntidad(){
            return {
                "facultad": this.facultad.value,
                "correo": this.correo,
                "nombre": this.nombre,
                "siglas": String(this.siglas).toUpperCase(),
            }
        },
        guardar(){
            if (this.valid == false) return;
            this.loading = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : 'POST',
                url : '/escuela',
                data: this.crearEntidad()
            }).then(response => {
                this.loading = false;
                Swal.fire({
                    title : "Sistema",
                    text: response.data.message,
                    icon: "success"
                }).then(() => {
                    this.$router.push('/escuela');
                });

            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;  
                }else{
                    errorMessage = e.message;
                }
                Swal.fire({
                    title : "Sistema",
                    text : errorMessage,
                    icon: "error"
                }).then(()=>{
                    this.loading = false;
                });
            })
        },
        getData(){
            this.loading = true;
            this.axios({
                headers : {
                    Authorization : "Bearer " + this.token
                },
                method: "get",
                url : "/facultad/getactives"
            }).then(response =>{
                response.data.forEach(x => {                
                    this.facultadData.push({
                        "title": x.Nombre,
                        "subtitle": x.Siglas,
                        "value": x.CodigoFacultad
                    });
                });
                this.loading = false;
            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;  
                }else{
                    errorMessage = e.message;
                }
                Swal.fire({
                    title : "Sistema",
                    text : errorMessage,
                    icon: "error"
                }).then(()=>{
                    this.loading = false;
                });
            });
        
        },
    },
    created(){
        this.getData();
    }
}
</script>

<style></style>