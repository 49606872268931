<!-- SideMenu.vue -->
<template>
    <v-content>
        <v-app-bar app color="primary" dark>
            <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>Menu {{ nameRol }}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" permanent app>
            <v-list>
                <v-list-item :title="username" :subtitle="nameRol" 
                    :prepend-avatar="rutaimagen"
                    nav></v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list density="compact" nav>
                <v-list-subheader>OPCIONES</v-list-subheader>
                <div class="list" v-for="(item, i) in items" :key="i" :value="item">
                    <div v-if="item.subitems.length == 0">
                        <v-list-item :to="item.url"  active-color="primary">
                            <template v-slot:prepend>
                                <v-icon :icon="item.icon"></v-icon>
                            </template>

                            <v-list-item-title v-text="item.title"></v-list-item-title>
                        </v-list-item>
                    </div>
                    <div v-else>
                        <v-list-group :value="item.title" >
                            <template v-slot:activator="{ props }">
                                <v-list-item v-bind="props" :prepend-icon="item.icon" :title="item.title"></v-list-item>
                            </template>

                            <v-list-item v-for="(subitem, j) in item.subitems" :key="j" :value="subitem"
                                :to="subitem.url" active-color="primary">
                                <template v-slot:prepend>
                                    <v-icon :icon="subitem.icon"></v-icon>
                                </template>

                                <v-list-item-title v-text="subitem.title"></v-list-item-title>
                            </v-list-item>
                        </v-list-group>
                    </div>

                </div>
            </v-list>
            <v-list nav>
                <v-list-item @click="closeSession">
                    <template v-slot:prepend>
                        <v-icon color="primary">mdi-exit-to-app</v-icon>
                    </template>
                    <v-list-item-title color="primary">Salir</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </v-content>
</template>
  
<script>
import { mapMutations } from 'vuex';
import Swal from 'sweetalert2'

export default {
    name: 'SideMenu',
    props: {
        ideRol: Number,
        nameRol: String,
        username: String
    },
    data() {
        return {
            token : this.$store.getters.token,
            drawer: true,
            itemsAdmin: [],
            itemsStudent: [],
            itemsDirector: [],
            items : [],
            rutaimagen: "https://appcartas.comercialcmi.com/user.png",
            //rutaimagen: "http://localhost:8080/user.png",
        };

    },
    methods: {
        ...mapMutations(['removeUser']),
        closeSession() {
            this.axios({
                headers: {
                    Authorization: "Bearer " + this.token
                },
                method: "POST",
                url: "/logout",
                
            }).then(response => {                
                Swal.fire({
                    title: "Sistema",
                    text: response.data.message,
                    icon: "success"
                }).then(() => {
                    this.removeUser();
                    this.$router.push('/');
                });
            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;
                } else {
                    errorMessage = e.message;
                }
                Swal.fire({
                    title: "Sistema",
                    text: errorMessage,
                    icon: "error"
                });
            });
        }
    },
    created(){
        this.itemsAdmin = [
            { title: "Inicio", icon: "mdi-desktop-mac-dashboard", url: "/home", subitems: []},
            { title: "Director", icon: "mdi-account-tie", url: "/director", subitems: [] },
            { title: "Escuela", icon: "mdi-school", url: "/escuela", subitems: [] },
            { title: "Alumno", icon: "mdi-account-star", url: "/alumno", subitems: [] },
            { title: "Usuario", icon: "mdi-account-group", url: "/usuario", subitems: [] },
            { title: "Asignar Director", icon: "mdi-account-switch", url: "/asignar", subitems: [] },            
        ];

        this.itemsStudent = [
            { title: "Inicio", icon: "mdi-desktop-mac-dashboard", url: "/home", subitems: []},
            //{ title: "Pago", icon: "mdi-cash", url: "/pago", subitems: [] },
            { title: "Carta", icon: "mdi-note", url: "/listacarta", subitems: [] },
            { title: "Cambiar Clave", icon: "mdi-lock-outline", url: "/cambiarclave", subitems: [] },
        ];

        this.itemsDirector = [
            { title: "Inicio", icon: "mdi-desktop-mac-dashboard", url: "/home", subitems: []},
            { title: "Cartas", icon: "mdi-note", url: "/cartasescuela", subitems: [] },
            { title: "Cambiar Clave", icon: "mdi-lock-outline", url: "/cambiarclave", subitems: [] },
        ];

        switch(this.ideRol){
            case 1: this.items = this.itemsAdmin; break;
            case 2: this.items = this.itemsDirector; break;
            case 3: this.items = this.itemsStudent; break;
        }
        
    },
    
};
</script>
  