import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from "../views/login/LoginView.vue";
import ChangePassword from "../views/login/ChangePasswordView.vue";
import ListDirector from "../views/directores/ListDirectorView.vue";
import EditDirector from "../views/directores/EditDirectorView.vue";
import NewDirector from "../views/directores/NewDirectorView.vue";
import ListEscuela from "../views/escuelas/ListEscuelaView.vue";
import EditEscuela from "../views/escuelas/EditEscuelaView.vue";
import NewEscuela from "../views/escuelas/NewEscuelaView.vue";
import ListAsignacion from "../views/asignaciones/ListAsignacionView.vue";
import EditAsignacion from "../views/asignaciones/EditAsignacionView.vue";
import NewAsignacion from "../views/asignaciones/NewAsignacionView.vue";
import ListAlumno from "../views/alumnos/ListAlumnoView.vue";
import NewAlumno from "../views/alumnos/NewAlumnoView.vue";
import EditAlumno from "../views/alumnos/EditAlumnoView.vue";
import ListUsuario from "../views/usuarios/ListUsuarioView.vue";

import CreateCarta from "../views/cartas/CreateCartaView.vue";
import ListCarta from "../views/cartas/ListCartaView.vue";
import EditCarta from "../views/cartas/EditCartaView.vue";

import ListCartaEscuela from "../views/cartas/ListCartaEscuelaView.vue"
import VerCartaEscuela from "../views/cartas/VerCartaView.vue"

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/director',
    name: 'Director',
    component: ListDirector
  },
  {
    path: '/nuevodirector',
    name: 'Nuevo Director',
    component: NewDirector
  },
  {
    path: '/editardirector/:id',
    name: 'Editar Director',
    component: EditDirector
  },
  {
    path: '/escuela',
    name: 'Escuela',
    component: ListEscuela
  },
  {
    path: '/nuevaescuela',
    name: 'Nuevo Escuela',
    component: NewEscuela
  },
  {
    path: '/editarescuela/:id',
    name: 'Editar Escuela',
    component: EditEscuela
  },
  {
    path: '/asignar',
    name: 'Asignacion',
    component: ListAsignacion
  },
  {
    path: '/nuevaasignacion',
    name: 'Nuevo Asignacion',
    component: NewAsignacion
  },
  {
    path: '/editarasignacion/:id',
    name: 'Editar Asignacion',
    component: EditAsignacion
  },
  {
    path: '/alumno',
    name: 'Alumno',
    component: ListAlumno
  },
  {
    path: '/nuevoalumno',
    name: 'Nuevo Alumno',
    component: NewAlumno
  },
  {
    path: '/editaralumno/:id',
    name: 'Editar Alumno',
    component: EditAlumno
  },
  {
    path: '/usuario',
    name: 'Usuario',
    component: ListUsuario
  },
  {
    path: '/cambiarclave',
    name: 'Cambiar Clave',
    component: ChangePassword
  },
  {
    path: '/carta',
    name: 'Cambiar Solicitud Carta',
    component: CreateCarta
  },
  {
    path: '/editarcarta/:id',
    name: 'Editar',
    component: EditCarta
  },
  {
    path: '/listacarta',
    name: 'Lista de Cartas',
    component: ListCarta
  },
  {
    path: '/cartasescuela',
    name: 'Lista de Cartas Escuela',
    component: ListCartaEscuela
  },
  {
    path: '/vercarta/:id',
    name: 'Ver Carta',
    component: VerCartaEscuela
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
