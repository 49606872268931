<template>
    <v-content>
      <v-row class="pa-5 align-center">
        <v-col cols="12">
          <h2 class="font-weight-bold text-center">Alumnos</h2>
        </v-col>
      </v-row>
      <v-card>
        <v-progress-linear color="primary" indeterminate :height="9" v-if="loading"></v-progress-linear>
        <v-card-title>
          <v-row>
            <v-col cols="4">
              <v-btn icon="mdi-plus" color="primary" title="Nuevo Alumno" to="/nuevoalumno"
                :disabled="cargando"></v-btn>                
            </v-col>
            <v-col cols="8">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar..." single-line
                hide-details></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table dense items-per-page="10" :loading="loading" :search="search" :headers="headers" :items="data"
          item-value="item" items-per-page-text="Total de Registros" class="elevation-1">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon title="Editar" color="success" small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon :title="item.Estado == 1 ? 'Dar de Baja' : 'Dar de Alta'" :color="item.Estado == 1 ? 'error' : 'warning'" small @click="deleteItem(item)">
              {{ item.Estado == 1 ? "mdi-delete" : "mdi-upload" }}
            </v-icon>
            <v-icon title="Asignar Usuario" color="primary" small class="mr-2" @click="asignarUser(item)">
              mdi-lock
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-content>
  </template>
  
  <script>
  import Swal from 'sweetalert2'
  export default {
    name: "Directores",
    data() {
      return {
        token : this.$store.getters.token,
        headers: [
          {
            title: "Codigo",
            align: "start",
            key: "CodigoUniversitario"
          },
          {
            title: "Nombres",
            align: "start",
            key: "Nombres",
            value: function(item){
                return String(item.Nombres + " " + item.ApPaterno + " " + item.ApMaterno).toUpperCase();
            }
          },
          {
            title: "Correo",
            align: "start",
            key: "CorreoUniversitario"
          },
          {
            title: "Fecha Ing.",
            align: "start",
            key: "FechaIngreso"
          },
          {
            title: "Escuela",
            align: "start",
            key: "Escuela"
          },          
          {
            title: "Estado",
            align: "start",
            key: "Estado",
            value: function(item){
              return item.Estado == 1 ? "ACTIVO" : "DESHABILITADO";
            }
          },
          {
            title: "Opciones",
            align: "start",
            key: "actions",
            sortable: false
          }
        ],
        search: null,
        loading: false,
        data: [],
        snackbarText: "",
        snackbar: false,
        snackbarColor: null
      }
    },
    methods: {
      editItem(item) {
        this.$router.push("/editaralumno/"+item.CodigoAlumno)
      },
      deleteItem(item) {
        this.axios({
            headers : {
              Authorization : "Bearer " + this.token
            },
            method : 'DELETE',
            url : '/alumno/'+item.CodigoAlumno,
        }).then(response => {
            this.loading = false;
            Swal.fire({
                title : "Sistema",
                text: response.data.message,
                icon: "success"
            }).then(() => {
                this.$router.go(0);
            });  
        }).catch(e => {
            let errorMessage = null;
            if (e.hasOwnProperty("response")) {
                errorMessage = e.response.data.message;  
            }else{
                errorMessage = e.message;
            }
            Swal.fire({
                title : "Sistema",
                text : errorMessage,
                icon: "error"
            }).then(()=>{
                this.loading = false;
            });
        })
      },
      asignarUser(item){
        let obj = {
          'nombre' : String(item.Nombres + " " + item.ApPaterno + " " + item.ApMaterno).toUpperCase(),
          'email' : item.CorreoUniversitario,
          'password' : item.CodigoUniversitario,
          'rol' : 3,
          'codigo' : item.CodigoAlumno
        };

        this.axios({
            headers : {
              Authorization : "Bearer " + this.token
            },
            method : 'POST',
            url : '/usuario',
            data: obj
        }).then(response => {
            this.loading = false;
            Swal.fire({
                title : "Sistema",
                text: response.data.message,
                icon: "success"
            }).then(() => {
                this.$router.go(0);
            });  
        }).catch(e => {
            let errorMessage = null;
            if (e.hasOwnProperty("response")) {
                errorMessage = e.response.data.message;  
            }else{
                errorMessage = e.message;
            }
            Swal.fire({
                title : "Sistema",
                text : errorMessage,
                icon: "error"
            }).then(()=>{
                this.loading = false;
            });
        })
      },
      getData(){
        this.loading = true;
        this.axios({
            headers : {
              Authorization : "Bearer " + this.token
            },
            method: "get",
            url : "/alumno"
        }).then(response =>{
            this.data = response.data;
            this.loading = false;
        }).catch(e => {
            let errorMessage = null;
            if (e.hasOwnProperty("response")) {
                errorMessage = e.response.data.message;  
            }else{
                errorMessage = e.message;
            }
            Swal.fire({
                title : "Sistema",
                text : errorMessage,
                icon: "error"
            }).then(()=>{
                this.loading = false;
            });
        });
      },
    },
    created() {
      //this.token = localStorage.getItem("token");
      this.getData();
    }
  
  }
  </script>
  