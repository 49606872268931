<template>
    <v-card>
        <v-card-text>
            <v-row class="pa-5 align-center">
                <v-col cols="11">
                    <v-row>
                        <h2 class="font-weight-regular text-center">Carta de Presentación</h2>
                    </v-row>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="12">
                        <v-text-field label="Codigo" readonly
                            persistent-hint
                            variant="outlined"
                            v-model="id"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="Facultad" readonly
                            persistent-hint
                            variant="outlined"
                            v-model="facultad"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="escuela" 
                            persistent-hint
                            readonly label="Escuela" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="alumno" label="Nombres" 
                            persistent-hint
                            readonly variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="empresa" label="Empresa" 
                            persistent-hint
                            readonly
                            hint="Razon Social"
                            variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="encargado" label="Encargado" 
                            persistent-hint
                            readonly
                            hint="Persona a la que se dirige la carta"
                            variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="cargo" label="Cargo" 
                            hint="Cargo que desempeña en la empresa mencionada"
                            persistent-hint
                            readonly
                            variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="number" v-model.number="periodo" label="Periodo (Meses)" 
                            persistent-hint
                            readonly
                            min="1"
                            max="6"
                            variant="outlined">
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-btn class="me-4 text-white" color="error" to="/cartasescuela">
                    Regresar
                </v-btn>

            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    data () {
        return {
            id: this.$route.params.id,
            token : this.$store.getters.token,
            loading: false,
            facultad : null,
            escuela : null,
            empresa: null,
            alumno: null,
            encargado: null,
            cargo: null,
            periodo: 3,
            valid: true,
            fieldRules: [(v) => !!v || "Campo requerido"],            
        }
    },
    methods: {
        validate() {
            this.$refs.form.validate();
        },
        getData(){
            this.loading = true;
            this.axios({
                headers : {
                    Authorization : "Bearer " + this.token
                },
                method: "get",
                url : "/vercarta/"+this.id
            }).then(response =>{
                this.facultad = response.data.Facultad;
                this.escuela = response.data.Escuela;
                this.alumno = response.data.ApPaterno + " " + response.data.ApMaterno + " " + response.data.Nombres;
                this.empresa = response.data.Empresa;
                this.encargado = response.data.Encargado;
                this.cargo = response.data.Cargo;
                this.periodo = response.data.Periodo;
                this.loading = false;
            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;  
                }else{
                    errorMessage = e.message;
                }
                Swal.fire({
                    title : "Sistema",
                    text : errorMessage,
                    icon: "error"
                }).then(()=>{
                    this.loading = false;
                });
            });
        
        },
    },
    created(){
        this.getData();
    }
}
</script>

<style></style>