<template>
    <v-content>
        <v-row class="pa-5 align-center">
            <v-col cols="12">
                <h2 class="font-weight-bold text-center">Usuarios</h2>
            </v-col>
        </v-row>
        <v-card>
            <v-progress-linear color="primary" indeterminate :height="9" v-if="loading"></v-progress-linear>
            <v-card-title>
                <v-row>
                    <v-col cols="4">
                        &nbsp;
                    </v-col>
                    <v-col cols="8">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar..." single-line
                            hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-data-table dense items-per-page="10" :loading="loading" :search="search" :headers="headers" :items="data"
                item-value="item" items-per-page-text="Total de Registros" class="elevation-1">
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon title="Restaurar Clave" color="success" small class="mr-2" @click="restaurarClave(item)">
                        mdi-restart
                    </v-icon>
                    <v-icon :title="item.estado == 1 ? 'Dar de Baja' : 'Dar de Alta'"
                        :color="item.estado == 1 ? 'error' : 'warning'" small @click="deleteItem(item)">
                        {{ item.estado == 1 ? "mdi-delete" : "mdi-upload" }}
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
    </v-content>
</template>
  
<script>
import Swal from 'sweetalert2'
export default {
    name: "Usuarios",
    data() {
        return {
            token : this.$store.getters.token,
            headers: [
                {
                    title: "Usuario",
                    align: "start",
                    key: "nombre"
                },
                {
                    title: "Correo",
                    align: "start",
                    key: "email"
                },
                {
                    title: "Rol",
                    align: "start",
                    key: "detallerol"
                },
                {
                    title: "Estado",
                    align: "start",
                    key: "estado",
                    value: function (item) {
                        return item.estado == 1 ? "ACTIVO" : "DESHABILITADO";
                    }
                },
                {
                    title: "Opciones",
                    align: "start",
                    key: "actions",
                    sortable: false
                }
            ],
            search: null,
            loading: false,
            data: [],
            snackbarText: "",
            snackbar: false,
            snackbarColor: null
        }
    },
    methods: {
        restaurarClave(item) {
            this.axios({
                headers : {
                  Authorization : "Bearer " + this.token
                },
                method: 'POST',
                url: '/usuario/restaurar/' + item.id,
            }).then(response => {
                this.loading = false;
                Swal.fire({
                    title: "Sistema",
                    text: response.data.message,
                    icon: "success"
                }).then(() => {
                    this.$router.go(0);
                });
            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;
                } else {
                    errorMessage = e.message;
                }
                Swal.fire({
                    title: "Sistema",
                    text: errorMessage,
                    icon: "error"
                }).then(() => {
                    this.loading = false;
                });
            })
        },
        deleteItem(item) {
            this.axios({
                headers : {
                  Authorization : "Bearer " + this.token
                },
                method: 'DELETE',
                url: '/usuario/' + item.id,
            }).then(response => {
                this.loading = false;
                Swal.fire({
                    title: "Sistema",
                    text: response.data.message,
                    icon: "success"
                }).then(() => {
                    this.$router.go(0);
                });
            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;
                } else {
                    errorMessage = e.message;
                }
                Swal.fire({
                    title: "Sistema",
                    text: errorMessage,
                    icon: "error"
                }).then(() => {
                    this.loading = false;
                });
            })
        },
        getData() {
            this.loading = true;
            this.axios({
                headers : {
                  Authorization : "Bearer " + this.token
                },
                method: "get",
                url: "/usuario"
            }).then(response => {
                this.data = response.data;
                this.loading = false;
            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;
                } else {
                    errorMessage = e.message;
                }
                Swal.fire({
                    title: "Sistema",
                    text: errorMessage,
                    icon: "error"
                }).then(() => {
                    this.loading = false;
                });
            });
        },
    },
    created() {
        //this.token = localStorage.getItem("token");
        this.getData();
    }

}
</script>
  