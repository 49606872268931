<template>
    <v-content>
      <v-row class="pa-5 align-center">
        <v-col cols="12">
          <h2 class="font-weight-bold text-center">Cartas Generadas</h2>
        </v-col>
      </v-row>
      <v-card>
        <v-progress-linear color="primary" indeterminate :height="9" v-if="loading"></v-progress-linear>
        <v-card-title>
          <v-row>
            <v-col cols="4">
              <v-btn icon="mdi-plus" color="primary" title="Nueva Carta" to="/carta"
                :disabled="cargando"></v-btn>                
            </v-col>
            <v-col cols="8">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar..." single-line
                hide-details></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table dense items-per-page="10" :loading="loading" :search="search" :headers="headers" :items="data"
          item-value="item" items-per-page-text="Total de Registros" class="elevation-1">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon title="Editar" color="success" small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon title="Descargar Carta Aprobada" color="error" small class="mr-2" @click="enviarRevision(item)">
              mdi-file-pdf-box
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-content>
  </template>
  
  <script>
  import Swal from 'sweetalert2'
  export default {
    name: "Directores",
    data() {
      return {
        token : this.$store.getters.token,
        headers: [
          {
            title: "Codigo",
            align: "start",
            key: "CodigoCarta"
          },
          {
            title: "Empresa",
            align: "start",
            key: "Empresa"
          },
          {
            title: "Periodo (Meses)",
            align: "start",
            key: "Periodo"
          },
          {
            title: "Fecha Registro",
            align: "start",
            key: "FechaRegistro"
          },         
          {
            title: "Estado",
            align: "start",
            key: "Estado",
            value: function(item){
              switch(Number(item.Estado)){
                case 0: return "ENVIADO";break;
                case 1: return "APROBADO";break;
              }
            }
          },
          {
            title: "Opciones",
            align: "start",
            key: "actions",
            sortable: false
          }
        ],
        search: null,
        loading: false,
        data: [],
        snackbarText: "",
        snackbar: false,
        snackbarColor: null
      }
    },
    methods: {
      editItem(item) {
        if (item.Estado == 1) {
          Swal.fire("Sistema","Carta ya fue aprobada, no puede ser Editada","warning");
          return;
        }
        this.$router.push("/editarcarta/"+item.CodigoCarta)
      },
      enviarRevision(item){
        if (item.Estado == 0) {
          Swal.fire("Sistema","Carta Aun no a sido aprobada","error");
          return;
        }

        this.axios({
            headers : {
              Authorization : "Bearer " + this.token,
            },
            method: "GET",
            url : "/generarcarta/"+item.CodigoCarta,
            responseType: 'blob'
        }).then(response =>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            let filename = "CARTA VIRTUAL N° " + item.CodigoCarta + ".pdf";
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            this.loading = false;
        }).catch(e => {
            console.log(e);
            let errorMessage = null;
            if (e.hasOwnProperty("response")) {
                errorMessage = e.response.data.message;  
            }else{
                errorMessage = e.message;
            }
            Swal.fire({
                title : "Sistema",
                text : errorMessage,
                icon: "error"
            }).then(()=>{
                this.loading = false;
            });
        });


      },
      getData(){
        this.loading = true;
        this.axios({
            headers : {
              Authorization : "Bearer " + this.token
            },
            method: "get",
            url : "/listacarta"
        }).then(response =>{
            this.data = response.data;
            this.loading = false;
        }).catch(e => {
            let errorMessage = null;
            if (e.hasOwnProperty("response")) {
                errorMessage = e.response.data.message;  
            }else{
                errorMessage = e.message;
            }
            Swal.fire({
                title : "Sistema",
                text : errorMessage,
                icon: "error"
            }).then(()=>{
                this.loading = false;
            });
        });
      },
    },
    created() {
      //this.token = localStorage.getItem("token");
      this.getData();
    }
  
  }
  </script>
  