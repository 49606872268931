import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

axios.defaults.baseURL = "https://apicartas.comercialcmi.com/api/"  //produccion
//axios.defaults.baseURL = "http://127.0.0.1:8000/api/"  //desarrollo
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueAxios,axios)
  .mount('#app')
