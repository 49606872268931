<template>
    <v-card>
        <v-progress-linear color="primary" indeterminate :height="9" v-if="cargando"></v-progress-linear>
        <v-card-text>
            <v-row class="pa-5 align-center">
                <v-col cols="11">
                    <v-row>
                        <h2 class="font-weight-regular text-center">Editar Asignacion de Director</h2>
                    </v-row>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="12">
                        <v-select label="Escuela" :rules="fieldRules" persistent-hint return-object variant="outlined"
                            v-model="escuela" :items="escuelaData"></v-select>
                    </v-col>
                    <v-col cols="8">
                        <v-select label="Director" :rules="fieldRules" persistent-hint return-object variant="outlined"
                            v-model="director" :items="directorData"></v-select>
                    </v-col>
                    <v-col cols="4">
                        <v-file-input label="Firma" 
                            show-size
                            counter
                            accept="image/*"
                            persistent-hint 
                            @change="handleFileChange"
                            @click:clear="limpiarArchivo"
                            variant="outlined"
                            v-model="firma"></v-file-input>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field type="date" label="Fecha Inicio" :rules="fieldRules" persistent-hint variant="outlined"
                            v-model="fechaini"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field type="date" label="Fecha Fin" persistent-hint variant="outlined"
                            v-model="fechafin"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-checkbox
                            v-model="vigencia"
                            label="Vigencia"
                        ></v-checkbox>
                    </v-col>
                </v-row>

                <v-btn class="me-4" color="success" :loading="loading" :disabled="loading" depressed @mousedown="validate"
                    @click="guardar">
                    Guardar
                </v-btn>
                <v-btn class="me-4 text-white" color="error" to="/asignar">
                    Regresar
                </v-btn>

            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    data () {
        return {
            id: this.$route.params.id,
            token : this.$store.getters.token,
            loading: false,
            cargando: true,
            escuela : null,
            firma: null,
            archivo: null,
            escuelaData : [],
            director : null,
            directorData : [],
            fechaini: null,
            fechafin: null,
            vigencia: true,
            valid: true,
            fieldRules: [(v) => !!v || "Campo requerido"],            
        }
    },
    methods: {
        validate() {
            this.$refs.form.validate();
        },

        handleFileChange(event) {
            this.archivo = event.target.files[0];
        },

        limpiarArchivo() {
            this.archivo = null;
        },
        crearEntidad(){
            let formData = new FormData();
            formData.append('_method', 'PUT');
            formData.append('firma', this.archivo);
            formData.append('escuela', this.escuela.value);
            formData.append('director', this.director.value);
            formData.append('fechaini', this.fechaini);
            if(this.fechafin){
                formData.append('fechafin', this.fechafin);
            }            
            formData.append('vigencia', this.vigencia);

            return formData;
        },
        guardar(){
            if (this.valid == false) return;

            this.loading = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token,
                    'Content-Type': 'multipart/form-data',
                    'X-HTTP-Method-Override': 'PUT'
                },
                method : 'POST',
                url : '/asignar/'+this.id,
                data: this.crearEntidad()
            }).then(response => {
                this.loading = false;
                Swal.fire({
                    title : "Sistema",
                    text: response.data.message,
                    icon: "success"
                }).then(() => {
                    this.$router.push('/asignar');
                });

            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;  
                }else{
                    errorMessage = e.message;
                }
                Swal.fire({
                    title : "Sistema",
                    text : errorMessage,
                    icon: "error"
                }).then(()=>{
                    this.loading = false;
                });
            })
        },
        getEscuelas(){
            this.loading = true;
            this.axios({
                headers : {
                    Authorization : "Bearer " + this.token
                },
                method: "get",
                url : "/escuela/get/actives"
            }).then(response =>{
                response.data.forEach(x => {                
                    this.escuelaData.push({
                        "title": x.Nombre,
                        "value": x.CodigoEscuela
                    });
                });
                this.loading = false;
            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;  
                }else{
                    errorMessage = e.message;
                }
                Swal.fire({
                    title : "Sistema",
                    text : errorMessage,
                    icon: "error"
                }).then(()=>{
                    this.loading = false;
                });
            });        
        },
        getDirectores(){
            this.loading = true;
            this.axios({
                headers : {
                    Authorization : "Bearer " + this.token
                },
                method: "get",
                url : "/director/get/actives"
            }).then(response =>{
                response.data.forEach(x => {                
                    this.directorData.push({
                        "title": x.Nombres + " " + x.ApPaterno + " " + x.ApMaterno,
                        "value": x.CodigoDirector
                    });
                });
                this.loading = false;
            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;  
                }else{
                    errorMessage = e.message;
                }
                Swal.fire({
                    title : "Sistema",
                    text : errorMessage,
                    icon: "error"
                }).then(()=>{
                    this.loading = false;
                });
            });        
        },

        getData(){
            this.cargando = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : "GET",
                url : "/asignar/"+this.id
            }).then(response => {
                this.escuela = this.escuelaData.find(x => x.value == response.data.CodigoEscuela);
                this.director = this.directorData.find(x => x.value == response.data.CodigoDirector);
                this.fechaini = response.data.FechaInicio;
                this.fechafin = response.data.FechaFin;
                this.vigencia = response.data.Vigencia == 1 ? true : false;
                this.cargando = false;
            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;  
                }else{
                    errorMessage = e.message;
                }
                Swal.fire({
                    title : "Sistema",
                    text : errorMessage,
                    icon: "error"
                }).then(()=>{
                    this.cargando = false;
                });
            })
        }
    },
    mounted() {
        this.getData();         
    },
    created(){
        this.getEscuelas();
        this.getDirectores();               
    }
}
</script>

<style></style>