<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" sm="12" md="8">
                <v-img
                    class="mx-auto my-6"
                    max-width="228"
                    src="../../assets/logou2023.png"
                ></v-img>

                <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
                    <v-card-title class="headline text-center">Iniciar sesión</v-card-title>
                    <v-card-text>
                        <!-- Formulario de inicio de sesion -->
                        <v-form @submit.prevent="login" ref="form" v-model="valid" lazy-validation>
                            <v-text-field type="email" v-model="username" label="Correo Electronico" required></v-text-field>
                            <v-text-field v-model="password" label="Contraseña" :type="showPassword ? 'text' : 'password'"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showPassword = !showPassword" required></v-text-field>

                            <v-btn @mousedown="validate" :disabled="ingresando"
                                type="submit" block color="primary">
                                <span v-if="!ingresando">Ingresar</span>
                                <v-progress-circular v-else size="20" width="2" indeterminate color="#FFF"></v-progress-circular>
                            </v-btn>
                        </v-form>    
                        <v-row class="mt-2" v-if="estado">
                            <v-col>
                                <v-btn text @click="forgotPassword">¿Olvidaste tu contraseña?</v-btn>
                            </v-col>
                        </v-row>
                        
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import store from '@/store/index'
import Swal from 'sweetalert2'

export default {
    name: "LoginView",
    data() {
        return {
            estado: false,
            snackbarText: "",
            snackbar: false,
            snackbarColor: null,
            username: '',
            password: '',
            showPassword: false,
            valid: true,
            ingresando: false,
            usuariosPrueba : []
        };
    },
    methods: {
        validate() {
            this.$refs.form.validate();
        },
        login() {
            if (this.valid == false) return;
            this.ingresando = true;

            let obj = {
                "email": this.username,
                "password": this.password
            }
            //this.$router.push('/home');
            //logica del login
            this.axios({
                method: "POST",
                url: "/login",
                data: obj
            }).then(response => {
                this.ingresando = false;
                const token = response.data.token   //response.data.token;
                const user = JSON.stringify(response.data.user);

                localStorage.setItem("user", user);
                localStorage.setItem("token", token);

                store.commit("updateUser");

                Swal.fire({
                    title: "Sistema",
                    text: response.data.message,
                    icon: "success"
                }).then(() => {
                    this.$router.push('/home');
                });

            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;
                } else {
                    errorMessage = e.message;
                }
                Swal.fire({
                    title: "Sistema",
                    text: errorMessage,
                    icon: "error"
                }).then(() => {
                    this.ingresando = false;
                });
            });

            /*let validar = this.usuariosPrueba.find(x => x.username == obj.username && x.password == obj.password);
            if (validar == null) {
                this.ingresando = false;
                this.snackbarText = "Error de credenciales";
                this.snackbarColor = "error";
                this.snackbar = true;
            }else{
                this.ingresando = false;
                const token = "token"   //response.data.token;
                const user = JSON.stringify(validar);

                localStorage.setItem("user", user);
                localStorage.setItem("token", token);

                store.commit("updateUser");

                this.snackbarText = "Credenciales Correctas";
                this.snackbarColor = "success";
                this.snackbar = true;

                setTimeout(() => {
                    this.$router.push('/home');
                }, 2000);
            }*/
            
        },
        forgotPassword() {
            console.log('Recuperar contraseña para:', this.username);
        }
    },
    created(){
        this.usuariosPrueba = [
            {
                "idrol": 1,
                "rol": "Administrador",
                "usuario": "Admin",
                "username": "admin@unprg.edu.pe",
                "password": "admin2024"
            },
            {
                "idrol": 2,
                "rol": "Estudiante",
                "usuario": "Robin",
                "username": "robin@unprg.edu.pe",
                "password": "robin2024"
            },
            {
                "idrol": 3,
                "rol": "Director de Escuela",
                "usuario": "Director",
                "username": "director@unprg.edu.pe",
                "password": "director2024"
            },

        ]
    }
};
</script>
  
  