<template>  
  <v-container class="pa-4 text-center" v-if="user.idrol == 1">
    <v-progress-linear color="primary" indeterminate :height="9" v-if="cargando"></v-progress-linear>
    <v-card>
      <v-card-title>
        <h1 class="text-h3 p-2">
          {{ sede }}
        </h1>
      </v-card-title>
    </v-card>
    <v-row
      class="fill-height"
      align="center"
      justify="center"
    >
      <template v-for="(item, i) in items" :key="i">
        <v-col
          cols="12"
          md="4"
        >
          <v-hover >
            <v-card>
              <v-card-title :class="['text-h6', `bg-${item.color}`]">
                <v-icon>{{ item.icon }}</v-icon>
                {{ item.title }}
              </v-card-title>
              <v-card-text class="bg-white text--primary">
                <p
                  :class="['text-h2', 'p-2' ,`text-${item.color}` ,'fw-bold']"
                >
                  {{ item.total }}
                </p>
                <p
                :class="['text-h6',`text-${item.color}`]"
                >
                  Registros
                </p>
                <v-btn
                  :to="item.url"
                  :color="item.color"
                  :disabled="cargando"
                >
                  <v-icon>mdi-arrow-right</v-icon>
                  Ir
                </v-btn>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </template>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-card>
      <v-card-title>
        <h1 class="text-h3 p-2">
          Bienvenido {{ user.usuario }}
        </h1>
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      token : this.$store.getters.token,
      user : this.$store.getters.user,
      cargando: true,
      sede: "UNIVERSIDAD NACIONAL PEDRO RUIZ GALLO",
      items: [
        {
          color: 'red-darken-4',
          icon: 'mdi-account-tie',
          title: 'Directores',
          url: '/director',
          total: 0
        },
        {
          color: 'purple-darken-2',
          icon: 'mdi-school',
          title: 'Escuelas',
          url: '/escuela',
          total: 0
        },
        {
          color: 'blue-darken-3',
          icon: 'mdi-account-star',
          title: 'Alumnos',
          url: '/alumno',
          total: 0
        },
      ],
      transparent: 'rgba(255, 255, 255, 0)',
    };
  },

  created(){
    this.cargando = true;
    this.axios({
      method : "GET",
      url : "/proceso/total",
      headers : {
        Authorization : 'Bearer ' + this.token
      }
    }).then( response => {
      let data = response.data;
      data.forEach(element => {
        this.items.forEach(x => {
          if(x.url == element.url){
            x.total = element.total;
          }
        });
      });
      this.cargando = false;
    })
  }

};
</script>
