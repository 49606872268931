<template>
    <v-card>
        <v-progress-linear color="primary" indeterminate :height="9" v-if="cargando"></v-progress-linear>
        <v-card-text>
            <v-row class="pa-5 align-center">
                <v-col cols="11">
                    <v-row>
                        <h2 class="font-weight-regular text-center">Editar Director</h2>
                    </v-row>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="4">
                        <v-text-field readonly v-model="codigo" maxLength="8" :rules="fieldRules" label="Cod. Universitario" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field readonly type="email" v-model="correo" :rules="emailRules" label="Correo Institucional" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field v-model="dni" maxLength="8" :rules="fieldRules" label="DNI" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field v-model="nombres" :rules="fieldRules" label="Nombres" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="appaterno" :rules="fieldRules" label="Ap. Paterno" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="apmaterno" :rules="fieldRules" label="Ap. Materno" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="date" v-model="fechanac" :rules="fieldRules" label="Fecha Nacimiento" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="date" v-model="fechaing" :rules="fieldRules" label="Fecha Ingreso" variant="outlined">
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-btn class="me-4" color="success" :loading="loading" :disabled="loading" depressed @mousedown="validate"
                    @click="guardar">
                    Guardar
                </v-btn>
                <v-btn class="me-4 text-white" color="error" to="/director">
                    Regresar
                </v-btn>

            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    data () {
        return {
            id : this.$route.params.id,
            token : this.$store.getters.token,
            loading: false,
            cargando: true,
            nombres : null,
            appaterno: null,
            apmaterno: null,
            fechaing: null,
            fechanac: null,
            dni: null,
            codigo: null,
            correo: null,
            valid: true,
            fieldRules: [(v) => !!v || "Campo requerido"],
            emailRules: [
                (v) => !!v || "Campo requerido",
                (v) => /.+@.+\..+/.test(v) || "El correo electronico debe ser valido"
            ]
            
        }
    },
    methods: {
        validate() {
            this.$refs.form.validate();
        },

        crearEntidad(){
            return {
                "dni": this.dni,
                "nombres": this.nombres,
                "appaterno": this.appaterno,
                "apmaterno": this.apmaterno,
                "fechanac": this.fechanac,
                "fechaing": this.fechaing,
            }
        },
        guardar(){
            if (this.valid == false) return;
            this.loading = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : 'PUT',
                url : '/director/'+this.id,
                data: this.crearEntidad()
            }).then(response => {
                this.loading = false;
                Swal.fire({
                    title : "Sistema",
                    text: response.data.message,
                    icon: "success"
                }).then(() => {
                    this.$router.push('/director');
                });

            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;  
                }else{
                    errorMessage = e.message;
                }
                Swal.fire({
                    title : "Sistema",
                    text : errorMessage,
                    icon: "error"
                }).then(()=>{
                    this.loading = false;
                });
            })
        },
        getData(){
            this.cargando = true;
            this.axios({
                headers : {
                    Authorization : 'Bearer ' + this.token
                },
                method : "GET",
                url : "/director/"+this.id
            }).then(response => {
                this.nombres = response.data.Nombres;
                this.apmaterno = response.data.ApMaterno;
                this.appaterno = response.data.ApPaterno;
                this.codigo = response.data.CodigoUniversitario;
                this.correo = response.data.CorreoUniversitario;
                this.dni = response.data.Dni;
                this.fechanac = response.data.FechaNac;
                this.fechaing = response.data.FechaIngreso;
                this.cargando = false;
            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;  
                }else{
                    errorMessage = e.message;
                }
                Swal.fire({
                    title : "Sistema",
                    text : errorMessage,
                    icon: "error"
                }).then(()=>{
                    this.cargando = true;
                });
            })
        }
    },
    created() {
        this.getData();
    }
}
</script>

<style></style>