<template>
    <v-content>
        <v-row class="pa-5 align-center">
            <v-col cols="12">
                <h2 class="font-weight-bold text-center">Lista de Cartas Generadas Por Alumnos</h2>
            </v-col>
        </v-row>
        <v-card>
            <v-progress-linear color="primary" indeterminate :height="9" v-if="loading"></v-progress-linear>
            <v-card-title>
                <v-row>
                    <v-col cols="4">
                        &nbsp;
                    </v-col>
                    <v-col cols="8">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar..." single-line
                            hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-data-table dense items-per-page="10" :loading="loading" :search="search" :headers="headers" :items="data"
                item-value="item" items-per-page-text="Total de Registros" class="elevation-1">
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon title="Revisar" color="success" small class="mr-2" @click="revisarCarta(item)">
                        mdi-eye-check-outline
                    </v-icon>
                    <v-icon title="Aprobar" color="primary" small class="mr-2" @click="aprobarCarta(item)">
                        mdi-check-all
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
    </v-content>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    name: "Cartas Generadas por alumnos",
    data() {
        return {
            token: this.$store.getters.token,
            headers: [
                {
                    title: "Codigo Carta",
                    align: "start",
                    key: "CodigoCarta"
                },
                {
                    title: "Codigo Alumno",
                    align: "start",
                    key: "CodigoUniversitario"
                },
                {
                    title: "Correo Alumno",
                    align: "start",
                    key: "CorreoUniversitario"
                },
                {
                    title: "Alumno",
                    align: "start",
                    key: "Nombres",
                    value: function(item){
                        return String(item.Nombres + " " + item.ApPaterno + " " + item.ApMaterno).toUpperCase();
                    }
                },
                {
                    title: "Fecha Registro",
                    align: "start",
                    key: "FechaRegistro"
                },
                {
                    title: "Celular",
                    align: "start",
                    key: "Celular"
                },
                {
                    title: "Estado",
                    align: "start",
                    key: "Estado",
                    value: function(item){
                        switch(Number(item.Estado)){
                            case 0: return "ENVIADO";break;
                            case 1: return "APROBADO";break;
                        }
                    }
                },
                {
                    title: "Opciones",
                    align: "start",
                    key: "actions",
                    sortable: false
                }
            ],
            search: null,
            loading: false,
            data: [],
            snackbarText: "",
            snackbar: false,
            snackbarColor: null
        }
    },
    methods: {
        revisarCarta(item) {
            this.$router.push("/vercarta/" + item.CodigoCarta)
        },
        aprobarCarta(item) {
            if(item.Estado == 1){
                Swal.fire("Sistema","Carta ya fue aprobada","info");
                return;
            }
            Swal.fire({
                title: 'Sistema',
                text: "Desea Aprobar Carta N° " + item.CodigoCarta + " ?",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.axios({
                        headers : {
                            Authorization : 'Bearer ' + this.token
                        },
                        method : 'POST',
                        url : '/aprobarcarta/'+item.CodigoCarta,
                    }).then(response => {
                        this.loading = false;
                        Swal.fire({
                            title : "Sistema",
                            text: response.data.message,
                            icon: "success"
                        }).then(() => {
                            this.$router.go(0);
                        });

                    }).catch(e => {
                        let errorMessage = null;
                        if (e.hasOwnProperty("response")) {
                            errorMessage = e.response.data.message;  
                        }else{
                            errorMessage = e.message;
                        }
                        Swal.fire({
                            title : "Sistema",
                            text : errorMessage,
                            icon: "error"
                        }).then(()=>{
                            this.loading = false;
                        });
                    })
                }
            });
        },
        getData() {
            this.loading = true;
            this.axios({
                headers: {
                    Authorization: "Bearer " + this.token
                },
                method: "get",
                url: "/cartasescuela"
            }).then(response => {
                this.data = response.data;
                this.loading = false;
            }).catch(e => {
                let errorMessage = null;
                if (e.hasOwnProperty("response")) {
                    errorMessage = e.response.data.message;
                } else {
                    errorMessage = e.message;
                }
                Swal.fire({
                    title: "Sistema",
                    text: errorMessage,
                    icon: "error"
                }).then(() => {
                    this.loading = false;
                });
            });
        },
    },
    created() {
        this.getData();
    }

}
</script>